
import { defineComponent, reactive, toRefs, ref, onMounted, VNodeChild, watch } from 'vue'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import { DownOutlined } from '@ant-design/icons-vue'
import { message, Empty } from 'ant-design-vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { searchUser, updateRoleType, deleteUser } from '@/api/index'
import AddUserDialog from './components/addUserDialog.vue'
import batchAddUser from './components/batchAddUser.vue'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '用户名',
    dataIndex: 'account',
    key: 'account'
  },
  {
    title: '用户姓名',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '用户身份',
    dataIndex: 'role',
    key: 'role',
    slots: { customRender: 'role' }
  },
  {
    title: '加入时间',
    dataIndex: 'add_time',
    key: 'add_time'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 250
  }
]
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '用户列表'
  },
  {
    id: 2,
    btnName: '添加用户'
  },
  {
    id: 3,
    btnName: '批量注册用户'
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}
export default defineComponent({
  name: 'UserManager',
  components: {
    DownOutlined,
    mangerPageHeader,
    AddUserDialog,
    batchAddUser
  },
  setup () {
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination }
    })
    const searchInput = ref('')
    // 获取百科列表
    const fetchUserHttp = (isDelete?: boolean) => {
      searchUser({
        search: searchInput.value.trim(),
        page: tableData.pagination.current,
        page_size: tableData.pagination.pageSize
      }).then((res) => {
        tableData.data = res.data
        tableData.pagination.total = res.result ? res.result : 0
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchUserHttp()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchUserHttp)
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchUserHttp()
    }
    const changeData = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchUserHttp()
    }
    // 修改用户身份
    const handleCommand = (id: number, { key }: MenuInfo) => {
      updateRoleType({
        account_id: id,
        role_id: Number(key)
      }).then(res => {
        if (res.success) {
          fetchUserHttp()
        }
      })
    }
    // 删除用户
    const deleteItem = (id: number) => {
      showConfirm({
        content: '此操作不可逆,确定要删除该用户吗?',
        callBack: () => {
          deleteUser({
            id: id
          }).then(res => {
            message.success('删除成功')
            fetchUserHttp(true)
          })
        }
      })
    }
    // 添加用户弹框
    const addUserDialogVisible = ref(false)
    const batchUserDialogVisible = ref(false)
    const handcancel = () => {
      addUserDialogVisible.value = false
      batchUserDialogVisible.value = false
      btnIndex.value = 0
      fetchUserHttp()
    }
    watch(btnIndex, (news, olds) => {
      if (news === 1) {
        addUserDialogVisible.value = true
      } else if (news === 2) {
        batchUserDialogVisible.value = true
      }
    })
    onMounted(() => {
      fetchUserHttp()
    })
    return {
      batchUserDialogVisible,
      handcancel,
      addUserDialogVisible,
      btnList,
      searchInput,
      changeIndex,
      btnIndex,
      ...toRefs(tableData),
      changeData,
      handleCommand,
      deleteItem,
      fetchSearch
    }
  }
})
