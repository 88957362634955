<template>
  <a-modal
    width="50%"
    v-model:visible="dialogVisible"
    title="添加用户"
    :footer="null"
    @cancel="cancelDialog">
    <a-input-search
      type="text"
      :maxlength="11"
      onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
      placeholder="搜索手机号"
      v-model:value="searchPhone"
      class="my-search"
      @keyup.enter="getUserData"
      @input="inputDelete"
    />
    <a-table
    v-if="searchUser.length>0 && !isOther && searchPhone!=''"
    :columns="columns"
    :data-source="searchUser"
    :pagination="false"
    :showHeader="false"
    rowKey="id"
    class="table">
      <template #role="{ record }" >
        <div class="role-type">
          <i class="dot" :style="{'background-color':record.role_id==73 ? 'red' : 'blue'}"></i>
            <span class="el-dropdown-link">
              {{record.role}}
            </span>
        </div>
      </template>
      <template #action1="{ record }">
        <a-button type="primary" v-if="isAdd" @click="addUserBtn(record.id)">
          <!-- <i class="el-icon-plus"></i> -->
          添加
        </a-button>
        <span v-else>已添加</span>
      </template>
    </a-table>
    <div v-if="isExistence" class="msgBox">该账号不存在</div>
    <div v-if="isOther && searchPhone!=''" class="msgBox">该账号已加入其他单位</div>
  </a-modal>
</template>
<script>
import { computed, defineComponent, toRefs, reactive } from 'vue'
import { message, Empty } from 'ant-design-vue'
import { addUser, addSearchUser } from '@/api/index'
import { getInfo } from '@/api/cookie'
const columns = [
  {
    title: '百科名称',
    dataIndex: 'mobile',
    key: 'mobile'
  },
  {
    title: '百科标签',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '角色',
    key: 'role',
    slots: { customRender: 'role' }
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 250
  }
]
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  props: ['visible'],
  setup (props, ctx) {
    const dialogVisible = computed(() => props.visible)
    const tableData = reactive({
      columns,
      data: [],
      pagination,
      searchPhone: '',
      searchUser: [],
      isAdd: '',
      isOther: '',
      isExistence: ''
    })
    // 关闭弹框
    const cancelDialog = () => {
      tableData.searchPhone = ''
      ctx.emit('handcancel')
    }
    const inputDelete = () => {
      tableData.isOther = ''
      tableData.isExistence = ''
      tableData.searchUser = []
    }
    const getUserData = () => {
      addSearchUser({
        search: tableData.searchPhone
      }).then(res => {
        const user_school_id = JSON.parse(getInfo()).school_id
        if (res.data !== null) {
          tableData.searchUser = []
          tableData.searchUser.push(res.data)
          tableData.isExistence = false
        } else {
          tableData.isExistence = true
        }
        if (res.data !== null && res.data.school_id === 0) {
          tableData.isAdd = true
        } else {
          tableData.isAdd = false
        }
        if (!tableData.isAdd && res.data.school_id !== user_school_id) {
          tableData.isOther = true
          tableData.isExistence = false
        } else {
          tableData.isOther = false
        }
      })
    }
    // 添加用户
    const addUserBtn = (id) => {
      addUser({
        id: id
      }).then(res => {
        if (res.success) {
          message.success('用户添加成功')
          getUserData()
        }
      })
    }
    return {
      addUserBtn,
      inputDelete,
      getUserData,
      ...toRefs(tableData),
      dialogVisible,
      cancelDialog
    }
  }
})
</script>
<style lang="scss" scoped>
// @import "~styles/var.scss";
.my-search{
  margin-bottom: 20px;
}
.btn {
  color: #fff;
  border: none;
  margin-left: 10px;
}
.role-type {
  display: flex;
  align-items: center;
}
.dot {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right:5px;
}
.msgBox{
  text-align: center;
}
@media (max-width: 1080px) {
  .width-dialog {
    /deep/.el-dialog {
      width: 80% !important;
      //  background-color: red;
    }
  }
}
</style>
