<template>
  <a-modal
    width="50%"
    v-model:visible="dialogVisible"
    title="批量注册用户"
    okText="确定"
    cancelText="取消"
    @cancel="cancelDialog"
    :footer="null">
    <div class="bths">
      <a :href="downUrl+'?'+'response-content-type=application/octet-stream'" class="btn" download="">
        <a-button type="primary">
          下载模板
        </a-button>
      </a>
        <a-upload
            class="upload"
            :accept="checkType"
            :showUploadList="false"
            :beforeUpload="handle"
        >
            <a-button type="primary">
              导入excel文件
            </a-button>
        </a-upload>
  </div>
  <div class="tipsBox">
      <span>导入结果：</span>
      <span v-html="tipsContent" @click="fetchFailData"></span>
  </div>
   <!-- :pagination="pagination" -->
    <!-- :pagination="pagination" -->
    <!-- @change="changeData" -->
  <a-table
    :columns="columns"
    :data-source="tableDataList"
    class="table"
    rowKey="index">
    <template #success="{ record }">
      <span class="spanText" :class="{failText:!record.success}">{{record.success ? '注册成功' : '注册失败'}}</span>
    </template>
    <template #action1="{ record }">
      <span style="color:#F4543E;cursor:pointer" v-if="record.operate == 1" @click="addUserBtn(record)">加入</span>
    </template>
  </a-table>

     <!-- 导入失败的数据列表 -->
     <failData :visible="failUserDialog" :failList="failList" @handcancel="closeBatchDialog"></failData>
  </a-modal>
</template>
<script>
import { computed, defineComponent, toRefs, reactive } from 'vue'
import xlsx from 'xlsx'
import { addUser, registerStudent } from '@/api/index'
import { message, Empty } from 'ant-design-vue'
import failData from './failData.vue'
const columns = [
  {
    title: '姓名',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    key: 'mobile'
  },
  {
    title: '注册状态',
    dataIndex: 'success',
    key: 'success',
    slots: { customRender: 'success' }
  },
  {
    title: '失败原因',
    dataIndex: 'msg',
    key: 'msg'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 250
  }
]
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  props: ['visible'],
  components: {
    failData
  },
  setup (props, ctx) {
    const dialogVisible = computed(() => props.visible)
    const tableData = reactive({
      columns,
      data: [],
      pagination,
      checkType: '.xlsx,.xls',
      tableDatas: [],
      tipsContent: '',
      tableDataList: [],
      status: 0,
      loading: '',
      failList: [],
      total: 0,
      failUserDialog: false,
      downUrl: 'https://arseek.oss-cn-chengdu.aliyuncs.com/system/wps/%E6%B3%A8%E5%86%8C%E5%AD%A6%E7%94%9F%E8%A1%A8.xls'
    })
    const getResultsTable = () => {
      tableData.tableDataList = tableData.tableDatas.map((item, index) => {
        item.index = index
        return item
      })
      console.log(tableData.tableDataList)
      // tableData.tableDataList = tableData.tableDatas.filter((item, index) => {
      //   return index < tableData.pagination.current * tableData.pagination.pageSize && index >= tableData.pagination.pageSize * (tableData.pagination.current - 1)
      // })
      // tableData.pagination.total = tableData.tableDatas.length
    }
    // 注册用户
    const registerUser = (item, length) => {
      // console.log(item, 'item')
      console.log(length)
      registerStudent({
        nickname: item['姓名'],
        mobile: item['手机号']
      }).then((res) => {
        tableData.status++
        tableData.tableDatas.push(res.data)
        if (tableData.status === (length - tableData.failList.length)) {
          // this.loading.close()
          tableData.status = 0
          tableData.tipsContent = `excel文件导入<span style="color:#1fb719">成功${tableData.tableDatas.length}条</span>数据，导入<span style="color:red;cursor:pointer;">失败${tableData.failList.length}条</span>数据`
          getResultsTable()
        }
      })
    }
    // 按照二进制读取文件
    const readFile = (file) => {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = e => {
          resolve(e.target.result)
        }
      })
    }
    // 读去导入的excel文件中的数据
    const readeExcelData = async (file) => {
      const excelData = await readFile(file)
      // console.log(excelData)
      const workbook = xlsx.read(excelData, { type: 'binary' })
      console.log(workbook, 'workbook')
      let list = []
      for (const sheet in workbook.Sheets) {
        if (workbook.Sheets[sheet].A1 && workbook.Sheets[sheet].B1) {
          if (workbook.Sheets[sheet].A1.v === '姓名' && workbook.Sheets[sheet].B1.v === '手机号') {
            list = list.concat(xlsx.utils.sheet_to_json(workbook.Sheets[sheet]))
          } else {
            tableData.tipsContent = `导入的Excel文件${sheet}页数据格式错误，请下载模板参照格式调整后重新导入`
            message.error(tableData.tipsContent)
            // this.loading.close(this.tipsContent)
            return
          }
        }
      }
      console.log(list, 'list')
      if (list.length > 0) {
        tableData.failList = []
        list.forEach((item, index) => {
          // console.log(item['姓名']!=undefined && item['手机号']!=undefined)
          if (item['姓名'] !== undefined && item['手机号'] !== undefined) {
            setTimeout(() => {
              // console.log(item)
              registerUser(item, (list.length))
            }, 100)
          } else {
            const obj = {
              id: index + 1,
              nickname: item['姓名'],
              mobile: item['手机号'],
              msg: ''
            }
            if (item['姓名'] === undefined) {
              obj.msg = '姓名不能为空'
            }
            if (item['手机号'] === undefined) {
              obj.msg = '手机号不能为空'
            }
            tableData.failList.push(obj)
          }
        })
      } else {
        tableData.tipsContent = '导入失败，表格为空'
        // this.loading.close()
      }
    }
    // 上传excel文件
    const handle = (file) => {
      console.log(file, 123)
      tableData.tableDatas = []
      tableData.pagination.page = 1
      // 文件类型检测
      const fileTypeIndex = file.name.lastIndexOf('.')
      const fileNameLength = file.name.length
      const fileType = file.name.substring(fileTypeIndex + 1, fileNameLength)
      if (tableData.checkType.indexOf(fileType) === -1) {
        message.error(`导入文件只支持${tableData.checkType}格式`)
        return false
      }
      // this.loading = Loading.service({
      //   text: '请稍等片刻，正在玩命处理中...',
      //   background: 'rgba(0,0,0,.5)'
      // })
      // 读去数据
      readeExcelData(file)
    }
    // 添加用户
    const addUserBtn = (item) => {
      addUser({
        id: item.id
      }).then(res => {
        if (res.success) {
          message.success('用户添加成功')
          item.operate = 0
          // this.$forceUpdate()
        }
      })
    }
    // 查看失败的数据
    const fetchFailData = () => {
      if (tableData.failList.length > 0) {
        tableData.failUserDialog = true
      }
    }
    const closeBatchDialog = (val) => {
      tableData.failUserDialog = false
    }
    // 关闭弹框
    const cancelDialog = () => {
      ctx.emit('handcancel')
    }
    const changeData = (pagination) => {
      tableData.pagination.current = pagination.current
      getResultsTable()
    }
    return {
      addUserBtn,
      dialogVisible,
      cancelDialog,
      ...toRefs(tableData),
      handle,
      changeData,
      fetchFailData,
      closeBatchDialog
    }
  }
})
</script>
<style lang="scss" scoped>
// @import "~styles/var.scss";
/deep/.el-dialog__header{
    text-align: left;
}
.btn {
  color: #fff;
  border: none;
  margin-right:10px;
  // margin-left: 10px;
//   position: absolute;
//   right:0;
}
.bths{
    width:100%;
    // position: relative;
    // background: red;
    // display: flex;
    // justify-content: right;
    // text-align: right !important;
}
.upload{
    width:auto;
    display: inline-block;
}
.spanText{
    color:#1fb719;
}
.failText{
    color:red;
}
.tipsBox{
    // background: red;
    margin-top:20px;
    text-align: left;
}
/deep/.el-table{
    margin-top:0;
    min-height:400px;
}
.page{
    margin-top:20px;
    text-align: center;
    cursor: pointer;
}
</style>
