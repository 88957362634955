<template>
  <a-modal
    width="50%"
    v-model:visible="dialogVisible"
    title="导入失败数据预览"
    :footer="null"
    @cancel="cancelDialog">
      <a-table
        :columns="columns"
        :data-source="failList"
        class="table"
        rowKey="id"></a-table>
  </a-modal>
</template>
<script>
import { computed, defineComponent } from 'vue'
const columns = [
  {
    title: '姓名',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    key: 'mobile'
  },
  {
    title: '失败原因',
    dataIndex: 'msg',
    key: 'msg'
  }
]
export default defineComponent({
  props: ['visible', 'failList'],
  setup (props, ctx) {
    const dialogVisible = computed(() => props.visible)
    // 关闭弹框
    const cancelDialog = () => {
      ctx.emit('handcancel')
    }
    return {
      dialogVisible,
      cancelDialog,
      columns
    }
  }
})
</script>
